"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CHAT_USERS_BY_IS_ADMIN_QUERY = exports.CHAT_ROOM_USER_LIST_QUERY = exports.CHAT_ROOM_QUERY = exports.CHAT_ROOM_LIST_QUERY = exports.CHAT_ROOM_BY_ADMIN_USER_QUERY = exports.CHAT_MESSAGES_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var CHAT_ROOM_QUERY = exports.CHAT_ROOM_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($roomId: Int) {\n  chat_room(where: {id: {_eq: $roomId}}) {\n    id\n    chat_room_name\n    site_user {\n      name\n      avatar\n    }\n    owner {\n      name\n      avatar\n    }\n    room_category {\n      category_name\n      category_name_site\n    }\n  }\n}\n"])));
var CHAT_MESSAGES_QUERY = exports.CHAT_MESSAGES_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ChatMessage($roomId: Int) {\n  messages(\n    where: {\n      chat_room_id: {_eq: $roomId},\n      deleted_at: {_is_null: true}\n    },\n    order_by: {created_at: asc}\n  ) {\n    messeger_content\n    id\n    from_user_id\n    file_url\n    file_name\n    chat_room_id\n    created_at\n    attach_file_type\n    chat_user {\n      name\n    }\n  }\n}\n"])));
var CHAT_ROOM_LIST_QUERY = exports.CHAT_ROOM_LIST_QUERY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  chat_room_category(\n    order_by: {id: asc, parent_id: asc},\n    where: {parent_id: {_is_null: true}}\n  ) {\n    category_name\n    category_name_site\n    id\n    rooms(distinct_on: chat_room_name) {\n      id\n      chat_room_category_id\n      chat_room_sub_category_id\n      chat_room_name\n      site_user_id\n      thumbnail\n    }\n  }\n}\n"])));
var CHAT_ROOM_USER_LIST_QUERY = exports.CHAT_ROOM_USER_LIST_QUERY = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery($categoryId: Int, $subCategoryId: Int, $adminId: uuid){\n  chat_room(\n    where: {\n      chat_room_category_id: {_eq: $categoryId},\n      chat_room_sub_category_id: {_eq: $subCategoryId},\n      site_user_id: {_eq: $adminId}\n    }) {\n    id\n    chat_room_name\n    messages(limit: 1, order_by: {created_at: desc}) {\n      chat_room_id\n      from_user_id\n      messeger_content\n      created_at\n      updated_at\n      attach_file_type\n      chat_user {\n        name\n      }\n      chat_room {\n        id\n      }\n    }\n    room_category {\n      category_name\n      category_name_site\n    }\n    owner {\n      name\n    }\n  }\n}\n"])));
var CHAT_USERS_BY_IS_ADMIN_QUERY = exports.CHAT_USERS_BY_IS_ADMIN_QUERY = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery($isAdmin: Boolean) {\n  chat_user(where: {is_siteuser: {_eq: $isAdmin}}) {\n    avatar\n    email\n    name\n    uid\n  }\n}\n"])));
var CHAT_ROOM_BY_ADMIN_USER_QUERY = exports.CHAT_ROOM_BY_ADMIN_USER_QUERY = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery($adminId: uuid) {\n  chat_room(where: {site_user_id: {_eq: $adminId}}) {\n    site_user_id\n    chat_room_name\n    chat_room_category_id\n    chat_room_sub_category_id\n    id\n    thumbnail\n  }\n}\n"])));
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title;
    var vnodes = [];
    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }
    if (title) {
      if (title === '求人情報' || title === '求人一覧') {
        if (Number((0, _auth.getRecruitmentAddStaffCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title]));
        }
      } else if (title === '個別契約書一覧') {
        if (Number((0, _auth.getContractCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title]));
        }
      } else if (title === '請求書確認一覧') {
        if (Number((0, _auth.getInvoiceCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title]));
        }
      } else if (title === '勤怠情報一覧') {
        if (Number((0, _auth.getAttendanceCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title]));
        }
      } else if (title === '労働通知書一覧') {
        if (Number((0, _auth.getLaborCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title]));
        }
      } else if (title === 'チャット') {
        if ((0, _auth.getClientChatCount)() === 'null') {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else if (Number((0, _auth.getClientChatCount)()) === 0) {
          vnodes.push(h("span", {
            "slot": 'title'
          }, [title]));
        } else {
          vnodes.push(h("span", {
            "style": 'color: #f00',
            "slot": 'title'
          }, [title, "(", (0, _auth.getClientChatCount)(), ")"]));
        }
      } else {
        vnodes.push(h("span", {
          "slot": 'title'
        }, [title]));
      }
    }
    return vnodes;
  }
};
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    showForm: {
      type: Object,
      default: function _default() {
        return {
          store_name: true,
          // 店名
          contact_name: true,
          // 担当者名
          phone_number: true,
          // 電話番号
          mail_address: true,
          // メールアドレス
          occupation: true,
          // 職種
          site_address: true,
          // 現場住所
          start_period_date: true,
          // 期間（開始）
          end_period_date: true,
          // 期間（終了）
          shift: true,
          // シフト内容
          staff_count: true,
          // 募集人数
          working_hours: true,
          // 勤務時間
          holiday: true,
          // 休日
          reception: true,
          // 待遇
          reason: true,
          // 募集理由
          workplace_composition: true,
          // 職場構成
          prerequisite_type: true,
          // 働く上での必須条件（性格）
          prerequisite_spec: true,
          // 働く上での必須条件（資格）
          condition_type: true,
          // 働く上でのでlきれば必要な条件（性格）
          condition_spec: true,
          // 働く上でのでlきれば必要な条件（資格）
          job_description: true // 仕事内容
        };
      }
    },
    form: {
      type: Object,
      default: function _default() {
        return {
          store_name: '',
          // 店名
          contact_name: '',
          // 担当者名
          phone_number: '',
          // 電話番号
          mail_address: '',
          // メールアドレス
          occupation: '',
          // 職種
          site_address: '',
          // 現場住所
          start_period_date: '',
          // 期間（開始）
          end_period_date: '',
          // 期間（終了）
          shift: '',
          // シフト内容
          staff_count: '',
          // 募集人数
          working_hours: '',
          // 勤務時間
          holiday: '',
          // 休日
          reception: '',
          // 待遇
          reason: '',
          // 募集理由
          workplace_composition: '',
          // 職場構成
          prerequisite_type: '',
          // 働く上での必須条件（性格）
          prerequisite_spec: '',
          // 働く上での必須条件（資格）
          condition_type: '',
          // 働く上でのでlきれば必要な条件（性格）
          condition_spec: '',
          // 働く上でのでlきれば必要な条件（資格）
          job_description: '' // 仕事内容
        };
      }
    },
    submitBtnName: {
      type: String,
      default: function _default() {
        return '新規作成';
      }
    },
    loading: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    var _this = this;
    var validateDateCheck = function validateDateCheck(rule, value, callback) {
      var start_date = (0, _moment.default)(_this.form.start_period_date);
      var end_date = (0, _moment.default)(_this.form.end_period_date);
      if (start_date.isAfter(end_date)) {
        _this.$message({
          message: '日付を正しく入力してください',
          type: 'error'
        });
        callback(new Error('日付を正しく入力してください'));
      } else {
        callback();
      }
    };
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('メールアドレスを正しく入力してください'));
      } else {
        callback();
      }
    };
    return {
      centerDialogVisible: false,
      rules: {
        store_name: [{
          required: true,
          message: '店名を入力してください',
          trigger: 'blur'
        }],
        contact_name: [{
          required: true,
          message: '担当者名を入力してください',
          trigger: 'blur'
        }],
        phone_number: [{
          required: true,
          message: '電話番号を入力してください',
          trigger: 'blur'
        }],
        mail_address: [{
          required: true,
          message: 'メールアドレスを入力してください',
          trigger: 'blur'
        }, {
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        occupation: [{
          required: true,
          message: '職種を入力してください',
          trigger: 'blur'
        }],
        site_address: [{
          required: true,
          message: '現場住所を入力してください',
          trigger: 'blur'
        }],
        start_period_date: [{
          required: false,
          trigger: 'blur',
          validator: validateDateCheck
        }],
        end_period_date: [{
          required: false,
          trigger: 'blur',
          validator: validateDateCheck
        }]
      }
    };
  },
  computed: {},
  methods: {
    submit: function submit() {
      var _this2 = this;
      console.log('submit');
      this.$refs.recruitmentForm.validate(function (valid) {
        if (!valid) {
          return;
        }
        _this2.centerDialogVisible = true;
      });
    },
    recruitmentProcess: function recruitmentProcess() {
      console.log('recruitmentProcess');
      this.centerDialogVisible = false;
      return this.$emit('postFormSubmit', this.form);
    }
  }
};
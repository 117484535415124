var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "clientForm",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.company_name,
                  expression: "showForm.company_name",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "company_name",
                        label: "社名",
                        rules: _vm.rules.company_name,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.company_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "company_name", $$v)
                              },
                              expression: "form.company_name",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.company_name },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.ceo_name,
                  expression: "showForm.ceo_name",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "ceo_name",
                        label: "担当者",
                        rules: _vm.rules.ceo_name,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.ceo_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ceo_name", $$v)
                              },
                              expression: "form.ceo_name",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.ceo_name },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.zip_code,
                  expression: "showForm.zip_code",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "zip_code",
                        label: "郵便番号",
                        rules: _vm.rules.zip_code,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.zip_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "zip_code", $$v)
                              },
                              expression: "form.zip_code",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.zip_code },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.address1,
                  expression: "showForm.address1",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "address1",
                        label: "住所",
                        rules: _vm.rules.address1,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.address1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address1", $$v)
                              },
                              expression: "form.address1",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.address1 },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.address2,
                  expression: "showForm.address2",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "address2", label: "ビル名など" } },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.address2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address2", $$v)
                              },
                              expression: "form.address2",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.address2 },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.phone_number,
                  expression: "showForm.phone_number",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "phone_number",
                        label: "電話番号",
                        rules: _vm.rules.phone_number,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.phone_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone_number", $$v)
                              },
                              expression: "form.phone_number",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.phone_number },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.fax_number,
                  expression: "showForm.fax_number",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "fax_number", label: "FAX番号" } },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.fax_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fax_number", $$v)
                              },
                              expression: "form.fax_number",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.fax_number },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.mobile_phone,
                  expression: "showForm.mobile_phone",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "mobile_phone", label: "携帯番号" } },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.mobile_phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobile_phone", $$v)
                              },
                              expression: "form.mobile_phone",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.mobile_phone },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.mail_address,
                  expression: "showForm.mail_address",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "mail_address",
                        label: "メールアドレス",
                        rules: _vm.rules.mail_address,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.mail_address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mail_address", $$v)
                              },
                              expression: "form.mail_address",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.mail_address },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.occupation,
                  expression: "showForm.occupation",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "occupation", label: "職種名" } },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.occupation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "occupation", $$v)
                              },
                              expression: "form.occupation",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.occupation },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.business_content,
                  expression: "showForm.business_content",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "business_content", label: "業務内容" } },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { type: "textarea", rows: 5 },
                            model: {
                              value: _vm.form.business_content,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "business_content", $$v)
                              },
                              expression: "form.business_content",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.business_content },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.login_id,
                  expression: "showForm.login_id",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "login_id",
                        label: "ID",
                        rules: _vm.rules.login_id,
                      },
                    },
                    [
                      _c(
                        "el-input",
                        _vm._b(
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.login_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "login_id", $$v)
                              },
                              expression: "form.login_id",
                            },
                          },
                          "el-input",
                          { disabled: _vm.disableForm.login_id },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.login_password,
                  expression: "showForm.login_password",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "login_password",
                            label: "パスワード",
                            rules: _vm.rules.login_password,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            _vm._b(
                              {
                                key: _vm.passwordType,
                                ref: "password",
                                attrs: {
                                  type: _vm.passwordType,
                                  placeholder: "英数字8文字以上",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.capsTooltip = false
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.checkCapslock($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.login_password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "login_password", $$v)
                                  },
                                  expression: "form.login_password",
                                },
                              },
                              "el-input",
                              { disabled: _vm.disableForm.login_password },
                              false
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            _vm._b(
                              {
                                staticClass: "show-pwd",
                                on: { click: _vm.showPwd },
                              },
                              "span",
                              { hidden: _vm.disableForm.login_password },
                              false
                            ),
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.login_password,
                  expression: "showForm.login_password",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Caps lock is On",
                        placement: "right",
                        manual: "",
                      },
                      model: {
                        value: _vm.capsTooltip,
                        callback: function ($$v) {
                          _vm.capsTooltip = $$v
                        },
                        expression: "capsTooltip",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "confirmed_password",
                            label: "パスワード確認",
                            rules: _vm.rules.confirmed_password,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            _vm._b(
                              {
                                key: _vm.passwordType,
                                ref: "password",
                                attrs: {
                                  type: _vm.passwordType,
                                  placeholder: "パスワード確認",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.capsTooltip = false
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.checkCapslock($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.confirmed_password,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "confirmed_password",
                                      $$v
                                    )
                                  },
                                  expression: "form.confirmed_password",
                                },
                              },
                              "el-input",
                              { disabled: _vm.disableForm.confirmed_password },
                              false
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            _vm._b(
                              {
                                staticClass: "show-pwd",
                                on: { click: _vm.showPwd },
                              },
                              "span",
                              { hidden: _vm.disableForm.confirmed_password },
                              false
                            ),
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.pass_check,
                  expression: "showForm.pass_check",
                },
              ],
            },
            [
              _c("el-col", { attrs: { md: 12 } }, [
                _c("label", [
                  _c(
                    "input",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.pass_check,
                            expression: "form.pass_check",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.pass_check)
                            ? _vm._i(_vm.form.pass_check, null) > -1
                            : _vm.form.pass_check,
                        },
                        on: {
                          click: _vm.activePwd,
                          change: function ($event) {
                            var $$a = _vm.form.pass_check,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "pass_check",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "pass_check",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "pass_check", $$c)
                            }
                          },
                        },
                      },
                      "input",
                      { disabled: _vm.disableForm.pass_check },
                      false
                    )
                  ),
                  _vm._v("パスワードを変更する\n        "),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticStyle: { color: "#f00" } }, [
            _vm._v(
              "※チェックを外している場合は、パスワードの更新が適用されません。"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-block" },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backToUserIndex")
                        },
                      },
                    },
                    [_vm._v("戻る")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(_vm._s(_vm.submitBtnName))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
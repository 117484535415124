var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("div", { staticClass: "header" }, [
          _c(
            "div",
            { staticClass: "header-title" },
            [
              _c("el-button", { on: { click: _vm.RecruitmentIndex } }, [
                _vm._v("戻る"),
              ]),
              _vm._v(" アカウント情報"),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("ClientForm", {
          attrs: {
            form: _vm.form,
            "show-form": _vm.showForm,
            "submit-btn-name": _vm.submitBtnName,
            loading: _vm.loading,
          },
          on: {
            backToUserIndex: _vm.RecruitmentIndex,
            postFormSubmit: _vm.postFormSubmit,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
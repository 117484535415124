"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileNameWithoutExtension = getFileNameWithoutExtension;
function getFileNameWithoutExtension(fileName) {
  // ファイル名から最後のドットの位置を見つける
  var lastDotIndex = fileName.lastIndexOf('.');

  // ドットが見つからない場合、または最初の文字がドットの場合（Unixの隠しファイル）
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return fileName;
  }

  // ファイル名から拡張子を除外して返す
  return fileName.substring(0, lastDotIndex);
}
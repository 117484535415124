"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.warningInformation = warningInformation;
function warningInformation() {
  return {
    PDFFileImportWarningMessages: 'PDFファイルのみアップロードすることができます。'
  };
}
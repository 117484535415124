"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayChatTime = displayChatTime;
exports.displayDateTime = displayDateTime;
var _moment = _interopRequireDefault(require("moment"));
_moment.default.updateLocale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});
function displayDateTime(dateTime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (!(0, _moment.default)(dateTime).isValid()) {
    return '';
  }
  if (!format) {
    format = 'YYYY/MM/DD HH:mm';
  }
  return (0, _moment.default)(dateTime).format(format);
}

/**
 * @param {Date} dateTime
 * @returns {String}
 */
function displayChatTime(dateTime) {
  var today = (0, _moment.default)(new Date());
  var yesterday = (0, _moment.default)(today).subtract(1, 'days');
  var date = '';
  var day = '';
  var time = (0, _moment.default)(dateTime).format('HH:mm');
  //  今日
  if ((0, _moment.default)(today).isSame((0, _moment.default)(dateTime), 'days')) {
    date = '今日';
    //  昨日
  } else if ((0, _moment.default)(yesterday).isSame((0, _moment.default)(dateTime), 'days')) {
    date = '昨日';
  } else {
    date = (0, _moment.default)(dateTime).format('MM月DD日');
    if (today.year() !== (0, _moment.default)(dateTime).year()) {
      date = (0, _moment.default)(dateTime).format('YYYY年MM月DD日');
    }
    day = (0, _moment.default)(dateTime).format('(ddd)');
  }
  return "".concat(date, " ").concat(day, " ").concat(time);
}
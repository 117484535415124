"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChatSubCategory = exports.ChatCategory = void 0;
var ChatCategory = exports.ChatCategory = {
  Work: {
    val: 1,
    label: 'お仕事に関するチャット',
    SubCategory: {
      noCharge: {
        val: 3,
        label: '担当者指定なし',
        avatar: process.env.VUE_APP_ICON_DEFAULT_ICON_URI,
        room_comment: 'お気軽にどうぞ！',
        prefix: '担当者なし'
      },
      Charge: {
        val: 4,
        label: '担当者への問い合わせ',
        avatar: process.env.VUE_APP_ICON_DEFAULT_ICON_URI,
        room_comment: 'お気軽にどうぞ！',
        prefix: '担当者'
      }
    }
  },
  Salary: {
    val: 2,
    label: 'お給料・その他に関するチャット',
    SubCategory: {
      Salary: {
        val: 5,
        label: 'お給料・登録情報について',
        avatar: process.env.VUE_APP_ICON_DEFAULT_ICON_URI,
        room_comment: 'お気軽にどうぞ！',
        prefix: '給料・登録情報'
      },
      Etc: {
        val: 6,
        label: 'その他',
        avatar: process.env.VUE_APP_ICON_DEFAULT_ICON_URI,
        room_comment: 'お気軽にどうぞ！',
        prefix: 'その他'
      }
    }
  }
};
var ChatSubCategory = exports.ChatSubCategory = {
  noCharge: ChatCategory.Work.SubCategory.noCharge,
  charge: ChatCategory.Work.SubCategory.Charge,
  salary: ChatCategory.Salary.SubCategory.Salary,
  etc: ChatCategory.Salary.SubCategory.Etc
};
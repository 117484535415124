"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileTypes = exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _appUtil = require("@/utils/app-util");
// 添付のタイプ 0: unknown 1: 写真 2:audio 3: video, 4 excel, 5 powerpoint, 6 word,7 zip, 8 pdf
var fileTypes = exports.fileTypes = {
  'image/*': 1,
  'audio/*': 2,
  'video/*': 3,
  'application/vnd.ms-excel': 4,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 4,
  'application/vnd.ms-powerpoint': 5,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 5,
  'application/msword': 6,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 6,
  'application/zip': 7,
  'application/pdf': 8
};
var _default = exports.default = {
  methods: {
    getUploadFileInfo: function getUploadFileInfo(file, path) {
      var data = [];
      if (!file || !path) {
        console.log('file not found');
        return data;
      }
      console.log('file');
      var fileName = file.name;
      var fileType = 0;
      if (file.type in fileTypes) {
        fileType = fileTypes[file.type];
      } else {
        var group = file.type.split('/')[0] + '/*';
        if (group in fileTypes) {
          fileType = fileTypes[group];
        }
      }
      var filePath = (0, _appUtil.makeFilePath)(path, fileName);
      data = {
        name: fileName,
        path: filePath,
        type: fileType
      };
      return data;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReleaseTypeList = exports.ReleaseType = void 0;
exports.releaseVal = releaseVal;
var ReleaseType = exports.ReleaseType = {
  All: {
    val: null,
    label: '全て',
    prefixLabel: '全て'
  },
  Release: {
    val: true,
    label: '公開',
    prefixLabel: '公開'
  },
  NotRelease: {
    val: false,
    label: '未公開',
    prefixLabel: '未公開'
  }
};
var ReleaseTypeList = exports.ReleaseTypeList = [ReleaseType.All, ReleaseType.Release, ReleaseType.NotRelease];
function releaseVal(val) {
  switch (val) {
    case ReleaseType.All.val:
      return ReleaseType.All.prefixLabel;
    case ReleaseType.Release.val:
      return ReleaseType.Release.prefixLabel;
    case ReleaseType.NotRelease.val:
      return ReleaseType.NotRelease.prefixLabel;
    default:
      return ReleaseType.All.prefixLabel;
  }
}
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ADMIN_QUERY = exports.ADMINS_LIST_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2;
var ADMIN_QUERY = exports.ADMIN_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($id: uuid) {\n  admin(where: {uid: {_eq: $id}}) {\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n    room_comment\n  }\n}\n"])));
var ADMINS_LIST_QUERY = exports.ADMINS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  admin(order_by: {id:asc}) {\n    id\n    uid\n    name\n    email\n    permission\n    avatar\n    has_chat_room\n  }\n}\n"])));
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAttendanceCount = getAttendanceCount;
exports.getClientChatCount = getClientChatCount;
exports.getClientDocumentID = getClientDocumentID;
exports.getClientID = getClientID;
exports.getContractCount = getContractCount;
exports.getInvoiceCount = getInvoiceCount;
exports.getLaborCount = getLaborCount;
exports.getMessageMode = getMessageMode;
exports.getRecruitmentAddStaffCount = getRecruitmentAddStaffCount;
exports.getRecruitmentID = getRecruitmentID;
exports.getRecruitmentMessageID = getRecruitmentMessageID;
exports.getStaffID = getStaffID;
exports.getToken = getToken;
exports.getUUID = getUUID;
exports.removeAttendanceCount = removeAttendanceCount;
exports.removeClientChatCount = removeClientChatCount;
exports.removeClientDocumentID = removeClientDocumentID;
exports.removeClientID = removeClientID;
exports.removeContractCount = removeContractCount;
exports.removeInvoiceCount = removeInvoiceCount;
exports.removeLaborCount = removeLaborCount;
exports.removeMessageMode = removeMessageMode;
exports.removeRecruitmentAddStaffCount = removeRecruitmentAddStaffCount;
exports.removeRecruitmentID = removeRecruitmentID;
exports.removeRecruitmentMessageID = removeRecruitmentMessageID;
exports.removeStaffID = removeStaffID;
exports.removeToken = removeToken;
exports.removeUUID = removeUUID;
exports.setAttendanceCount = setAttendanceCount;
exports.setClientChatCount = setClientChatCount;
exports.setClientDocumentID = setClientDocumentID;
exports.setClientID = setClientID;
exports.setContractCount = setContractCount;
exports.setInvoiceCount = setInvoiceCount;
exports.setLaborCount = setLaborCount;
exports.setMessageMode = setMessageMode;
exports.setRecruitmentAddStaffCount = setRecruitmentAddStaffCount;
exports.setRecruitmentID = setRecruitmentID;
exports.setRecruitmentMessageID = setRecruitmentMessageID;
exports.setStaffID = setStaffID;
exports.setToken = setToken;
exports.setUUID = setUUID;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// import { comparePassword } from '@/utils/app-util'
// import { apolloClient } from '@/apollo/apolloClient'
// import { CLIENT_LOGIN } from '@/apollo/queries/client-query'

var TokenKey = 'fs_client_Token';
var ClientUuidKey = 'fs_client_uuid';
var ClientIdKey = 'fs_client_id';
var RecruitmentIDKey = 'fs_client_recruitmentID';
var StaffIDKey = 'fs_client_staffID';
var RecruitmentMessageIDKey = 'fs_client_recruitmentMessageID';
var ClientDocumentIDKey = 'fs_client_client_document_id';
var MessageModeKey = 'fs_client_message_mode';
var ContractCountKey = 'fs_client_contract_count';
var InvoiceCountKey = 'fs_client_invoice_count';
var AttendanceCountKey = 'fs_client_attendance_count';
var LaborCountKey = 'fs_client_labor_count';
var RecruitmentAddStaffCountKey = 'fs_client_recruitment_addstaff_count';
var ClientChatCountKey = 'fs_client_client_chat_count';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getUUID() {
  return _jsCookie.default.get(ClientUuidKey);
}
function setUUID(value) {
  return _jsCookie.default.set(ClientUuidKey, value);
}
function removeUUID() {
  return _jsCookie.default.remove(ClientUuidKey);
}
function getClientID() {
  return _jsCookie.default.get(ClientIdKey);
}
function setClientID(value) {
  return _jsCookie.default.set(ClientIdKey, value);
}
function removeClientID() {
  return _jsCookie.default.remove(ClientIdKey);
}
function getRecruitmentID() {
  return _jsCookie.default.get(RecruitmentIDKey);
}
function setRecruitmentID(value) {
  return _jsCookie.default.set(RecruitmentIDKey, value);
}
function removeRecruitmentID() {
  return _jsCookie.default.remove(RecruitmentIDKey);
}
function getStaffID() {
  return _jsCookie.default.get(StaffIDKey);
}
function setStaffID(value) {
  return _jsCookie.default.set(StaffIDKey, value);
}
function removeStaffID() {
  return _jsCookie.default.remove(StaffIDKey);
}
function getRecruitmentMessageID() {
  return _jsCookie.default.get(RecruitmentMessageIDKey);
}
function setRecruitmentMessageID(value) {
  return _jsCookie.default.set(RecruitmentMessageIDKey, value);
}
function removeRecruitmentMessageID() {
  return _jsCookie.default.remove(RecruitmentMessageIDKey);
}
function setClientDocumentID(value) {
  return _jsCookie.default.set(ClientDocumentIDKey, value);
}
function getClientDocumentID() {
  return _jsCookie.default.get(ClientDocumentIDKey);
}
function removeClientDocumentID() {
  return _jsCookie.default.remove(ClientDocumentIDKey);
}
function setMessageMode(value) {
  return _jsCookie.default.set(MessageModeKey, value);
}
function getMessageMode() {
  return _jsCookie.default.get(MessageModeKey);
}
function removeMessageMode() {
  return _jsCookie.default.remove(MessageModeKey);
}
function setContractCount(value) {
  return _jsCookie.default.set(ContractCountKey, value);
}
function getContractCount() {
  return _jsCookie.default.get(ContractCountKey);
}
function removeContractCount() {
  return _jsCookie.default.remove(ContractCountKey);
}
function setInvoiceCount(value) {
  return _jsCookie.default.set(InvoiceCountKey, value);
}
function getInvoiceCount() {
  return _jsCookie.default.get(InvoiceCountKey);
}
function removeInvoiceCount() {
  return _jsCookie.default.remove(InvoiceCountKey);
}
function setAttendanceCount(value) {
  return _jsCookie.default.set(AttendanceCountKey, value);
}
function getAttendanceCount() {
  return _jsCookie.default.get(AttendanceCountKey);
}
function removeAttendanceCount() {
  return _jsCookie.default.remove(AttendanceCountKey);
}
function setLaborCount(value) {
  return _jsCookie.default.set(LaborCountKey, value);
}
function getLaborCount() {
  return _jsCookie.default.get(LaborCountKey);
}
function removeLaborCount() {
  return _jsCookie.default.remove(LaborCountKey);
}
function setRecruitmentAddStaffCount(value) {
  return _jsCookie.default.set(RecruitmentAddStaffCountKey, value);
}
function getRecruitmentAddStaffCount() {
  return _jsCookie.default.get(RecruitmentAddStaffCountKey);
}
function removeRecruitmentAddStaffCount() {
  return _jsCookie.default.remove(RecruitmentAddStaffCountKey);
}
function setClientChatCount(value) {
  return _jsCookie.default.set(ClientChatCountKey, value);
}
function getClientChatCount() {
  return _jsCookie.default.get(ClientChatCountKey);
}
function removeClientChatCount() {
  return _jsCookie.default.remove(ClientChatCountKey);
}

/*
export async function clientLogin(id, password) {
  const client = await apolloClient.query({
    query: CLIENT_LOGIN,
    variables: {
      login_id: id.trim()
    }
  })
  if (!client.data.client_user.length) {
    return Promise.reject('IDかパスワードが間違っています')
  }
  const isValid = await comparePassword(password.trim(), client.data.client_user[0].login_password)
  if (!isValid) {
    return Promise.reject('IDパスワードが間違っています')
  }
 return Promise.resolve(client)
}
*/
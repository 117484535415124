var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c("h3", { staticClass: "header-title" }, [_vm._v("請求書確認一覧")]),
        _vm._v(" "),
        _c("InvoiceFilter"),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "32px" } },
          [
            _c("label", [
              _vm._v(
                _vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goTopPage()
                  },
                },
              },
              [_vm._v("先頭ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goPrePage()
                  },
                },
              },
              [_vm._v("前ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goNextPage()
                  },
                },
              },
              [_vm._v("次ページ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "button-first",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goLastPage()
                  },
                },
              },
              [_vm._v("最終ページ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.clients_invoice, stripe: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "タイトル", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.client_reading_flag == true
                            ? _c("div", [
                                _c("span", { staticStyle: { color: "#f00" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.title) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.client_reading_flag == false
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(scope.row.title) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "公開日時", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.client_reading_flag == true
                            ? _c("div", [
                                _c("span", { staticStyle: { color: "#f00" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.displayDateTime(
                                          scope.row.release_date,
                                          "YYYY年MM月DD日 HH:mm"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.client_reading_flag == false
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.displayDateTime(
                                          scope.row.release_date,
                                          "YYYY年MM月DD日 HH:mm"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "承認日時", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.client_reading_flag == true
                            ? _c("div", [
                                scope.row.is_acceptance === true
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#f00" } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.displayDateTime(
                                                scope.row.date_of_acceptance,
                                                "YYYY年MM月DD日 HH:mm"
                                              )
                                            )
                                        ),
                                        scope.row.date_of_acceptance !== null
                                          ? _c("div", [
                                              _c("br"),
                                              _vm._v(
                                                "取り消し有効期限 " +
                                                  _vm._s(
                                                    _vm.displayexpiration_date(
                                                      scope.row
                                                        .date_of_acceptance,
                                                      "YYYY年MM月DD日"
                                                    )
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("div", [
                                scope.row.is_acceptance === true
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.displayDateTime(
                                              scope.row.date_of_acceptance,
                                              "YYYY年MM月DD日 HH:mm"
                                            )
                                          )
                                      ),
                                      scope.row.date_of_acceptance !== null
                                        ? _c("div", [
                                            _c("br"),
                                            _vm._v(
                                              "取り消し有効期限 " +
                                                _vm._s(
                                                  _vm.displayexpiration_date(
                                                    scope.row
                                                      .date_of_acceptance,
                                                    "YYYY年MM月DD日"
                                                  )
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "承認有無",
                    align: "center",
                    "min-width": "35",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.client_reading_flag == true
                            ? _c("div", [
                                _c("span", { staticStyle: { color: "#f00" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.displayAcceptanceType(
                                          scope.row.is_acceptance,
                                          scope.row.date_of_acceptance
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.client_reading_flag == false
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.displayAcceptanceType(
                                          scope.row.is_acceptance,
                                          scope.row.date_of_acceptance
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "buttons",
                  attrs: { align: "center", "min-width": "75" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.client_reading_flag == true
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "background-color": "#f00",
                                        color: "#fff",
                                      },
                                      attrs: { type: "info" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToDetail(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("請求書確認")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.client_reading_flag == false
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "info", plain: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToDetail(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("請求書確認")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "32px" } },
      [
        _c("label", [
          _vm._v(_vm._s(_vm.currentPage + 1) + " / " + _vm._s(_vm.maxPage + 1)),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goTopPage()
              },
            },
          },
          [_vm._v("先頭ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goPrePage()
              },
            },
          },
          [_vm._v("前ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goNextPage()
              },
            },
          },
          [_vm._v("次ページ")]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "button-first",
            attrs: { type: "info", plain: "" },
            on: {
              click: function ($event) {
                return _vm.goLastPage()
              },
            },
          },
          [_vm._v("最終ページ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
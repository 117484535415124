"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPSERT_CHAT_USER_AND_ROOM = exports.INSERT_CHAT_ROOM = exports.ADD_CHAT_USER = exports.ADD_CHAT_MESSAGE = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var ADD_CHAT_USER = exports.ADD_CHAT_USER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatUser(\n  $objects: [chat_user_insert_input!]!\n) {\n  insert_chat_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_user_uid_key,\n      update_columns: [\n        name,\n        email,\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      avatar\n      is_siteuser\n      name\n      email\n      uid\n    }\n  }\n}\n"])));
var UPSERT_CHAT_USER_AND_ROOM = exports.UPSERT_CHAT_USER_AND_ROOM = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation addOrUpdateChatUser(\n  $objects: [chat_user_insert_input!]!,\n  $adminId: uuid,\n  $chatRoomObj: chat_room_set_input\n) {\n  insert_chat_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_user_uid_key,\n      update_columns: [\n        name,\n        email,\n        avatar,\n  \t\t\tis_siteuser\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      avatar\n      is_siteuser\n      name\n      email\n      uid\n    }\n  }\n  update_chat_room (\n    where: {site_user_id: {_eq: $adminId}}\n    _set: $chatRoomObj\n  ) {\n    affected_rows\n  }\n}\n"])));
var INSERT_CHAT_ROOM = exports.INSERT_CHAT_ROOM = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatRoom($objects: [chat_room_insert_input!]!) {\n  insert_chat_room(\n    objects: $objects,\n    on_conflict:{\n      constraint: chat_room_owner_id_site_user_id_chat_room_sub_category_id_key,\n      update_columns: [\n        chat_room_name,\n        thumbnail\n        room_comment\n      ]\n    }\n  ) {\n    affected_rows\n    returning {\n      id\n      owner_id\n      site_user_id\n      chat_room_category_id\n      chat_room_sub_category_id\n      chat_room_name\n      thumbnail\n      room_comment\n      room_members {\n        user_id\n        chat_room_id\n      }\n    }\n  }\n}\n"])));
var ADD_CHAT_MESSAGE = exports.ADD_CHAT_MESSAGE = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatMessage(\n    $message: String,\n    $attachFileType: Int,\n    $fileName: String,\n    $fileUrl: String,\n    $adminId: uuid,\n    $chatRoomId: Int, \n  ) {\n  insert_messages(objects: {\n    messeger_content: $message,\n    attach_file_type: $attachFileType,\n    file_name: $fileName,\n    file_url: $fileUrl,\n    chat_room_id: $chatRoomId,\n    from_user_id: $adminId,\n  }) {\n    returning {\n      messeger_content\n      id\n      from_user_id\n      file_url\n      file_name\n      chat_room_id\n      created_at\n      attach_file_type\n      chat_user {\n        name\n      }\n    }\n  }\n}\n"])));
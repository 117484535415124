"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_CONTRACT_APPROVAL = exports.UPDATE_CLIENT_READING_FLAG = exports.UPDATE_CLIENT = exports.CREATE_DOCUMENT = exports.CLEAR_CLIENT_CHAT_COUNT = exports.ADD_CHAT_MESSAGE = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
var UPDATE_CLIENT = exports.UPDATE_CLIENT = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateClient(\n  $uid: uuid,\n  $objects: client_user_set_input\n) {\n  update_client_user(\n    where: {uid: {_eq: $uid}},\n    _set: $objects\n  ) {\n    affected_rows,\n    returning {\n      id\n      uid\n      company_name\n      ceo_name\n      zip_code\n      address1\n      address2\n      phone_number\n      fax_number\n      mobile_phone\n      mail_address\n      occupation\n      business_content\n      login_id\n      login_password\n      is_login\n      created_at\n      updated_at\n    }  \n  }\n}\n"])));
var UPDATE_CONTRACT_APPROVAL = exports.UPDATE_CONTRACT_APPROVAL = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateContractApproval(\n  $id: Int,\n  $is_acceptance: Boolean\n) {\n  update_client_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      is_acceptance: $is_acceptance,\n      date_of_acceptance: \"now\",\n      admin_reading_flag: true\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      client_id\n      title\n      file_type\n      file_url\n      file_name\n      attach_file_type\n      is_acceptance\n      date_of_acceptance\n      create_date\n    }  \n  }\n}\n"])));
var CREATE_DOCUMENT = exports.CREATE_DOCUMENT = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation createClientContract($objects: [client_file_link_insert_input!]!) {\n  insert_client_file_link(\n    objects: $objects\n  ) {\n    affected_rows\n    returning {\n      id\n      title\n      file_url\n      file_name\n      attach_file_type\n      file_type\n      client_id\n      create_date\n      is_acceptance\n      date_of_acceptance\n    }\n  }\n}\n"])));
var UPDATE_CLIENT_READING_FLAG = exports.UPDATE_CLIENT_READING_FLAG = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation udpateClientReadingFlag(\n  $id: Int,\n  $client_reading_flag: Boolean\n) {\n  update_client_file_link(\n    where: {id: {_eq: $id}},\n    _set: {\n      client_reading_flag: $client_reading_flag\n    }\n  ) {\n    affected_rows,\n    returning {\n      id\n      client_id\n      title\n      file_type\n      file_url\n      file_name\n      attach_file_type\n      is_acceptance\n      date_of_acceptance\n      create_date\n      release_date\n      client_reading_flag\n    }  \n  }\n}\n"])));
var ADD_CHAT_MESSAGE = exports.ADD_CHAT_MESSAGE = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation addChatMessage(\n  $message: String,\n  $from_user_type: Int\n  $client_uid: uuid,\n  $admin_uid: uuid\n) {\ninsert_client_message(objects: {\n  message: $message,\n  from_user_type: $from_user_type,\n  client_uid: $client_uid,\n  admin_uid: $admin_uid,\n  unread_count: 1,\n  create_date: \"now\"\n}) {\n  returning {\n    id\n    message\n    from_user_type\n    client_uid\n    admin_uid\n    create_date\n    client_user {\n      company_name\n    }\n    admin_user {\n      name\n    }\n  }\n}\n}\n"])));
var CLEAR_CLIENT_CHAT_COUNT = exports.CLEAR_CLIENT_CHAT_COUNT = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation clearClientChatCount($client_uid: uuid) {\n  update_client_message(where: {client_uid: {_eq: $client_uid}, from_user_type: {_eq: 1}}, _set: {unread_count: 0}) {\n    affected_rows\n  }\n}\n"])));
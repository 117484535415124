var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "AttendanceForm",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.title,
                  expression: "showForm.title",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "title",
                        label: "タイトル",
                        rules: _vm.rules.title,
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showForm.pdf_file,
                  expression: "showForm.pdf_file",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { md: 15 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fileList",
                        label: "添付ファイル（PDF）",
                        rules: _vm.rules.fileList,
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload",
                          attrs: {
                            drag: "",
                            action: "",
                            limit: 1,
                            "on-change": _vm.handleAdd,
                            "on-remove": _vm.handleRemove,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList,
                            "list-type": "pdf",
                            "auto-upload": false,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("ファイルをドロップする"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.orgImgData.length > 0 && _vm.fileList.length === 0
                        ? _c("div", [
                            _c("img", {
                              staticClass: "preview",
                              attrs: { src: _vm.orgImgData },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-block" },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backToContractIndex")
                        },
                      },
                    },
                    [_vm._v("戻る")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(_vm._s(_vm.submitBtnName))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "確認",
                visible: _vm.centerDialogVisible,
                width: "30%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.centerDialogVisible = $event
                },
              },
            },
            [
              _c("span", [
                _vm._v("書類の" + _vm._s(_vm.submitBtnName) + "を行いますか？"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.centerDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("いいえ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addAttendanceForm },
                    },
                    [_vm._v("はい")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/',
  component: _layout.default,
  redirect: '/recruitment',
  meta: {
    title: '求人情報',
    roles: ['admin', 'editor'] // you can set roles in root nav
  },
  children: [{
    path: 'recruitment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/index'));
      });
    },
    name: 'RecruitmentIndex',
    meta: {
      title: '求人一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/create'));
      });
    },
    name: 'RecruitmentCreate',
    meta: {
      title: '求人作成',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/edit'));
      });
    },
    name: 'RecruitmentEdit',
    hidden: true,
    meta: {
      title: '求人詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/detail'));
      });
    },
    name: 'RecruitmentDetail',
    hidden: true,
    meta: {
      title: '求人詳細',
      noCache: true,
      breadcrumb: false,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/recruitment/message',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recruitment/message'));
      });
    },
    name: 'RecruitmentMessage',
    hidden: true,
    meta: {
      title: 'メッセージルーム',
      noCache: true,
      roles: ['admin']
    }
  }]
}, {
  path: '/contract',
  component: _layout.default,
  redirect: '/contract',
  meta: {
    title: '個別契約書',
    roles: ['admin', 'editor'] // you can set roles in root nav
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/contract/index'));
      });
    },
    name: 'ContractIndex',
    meta: {
      title: '個別契約書一覧',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }, {
    path: '/contract/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/contract/detail'));
      });
    },
    name: 'ContractDetail',
    hidden: true,
    meta: {
      title: '電子個別契約書詳細',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/invoice',
  component: _layout.default,
  redirect: '/invoice',
  meta: {
    title: '請求書確認',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/invoice/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/invoice/index'));
      });
    },
    name: 'InvoiceIndex',
    meta: {
      title: '請求書確認一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/invoice/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/invoice/detail'));
      });
    },
    name: 'InvoiceDetail',
    hidden: true,
    meta: {
      title: '請求書確認',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }]
}, {
  path: '/attendance',
  component: _layout.default,
  redirect: '/attendance',
  meta: {
    title: '勤怠情報',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/attendance/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/attendance/index'));
      });
    },
    name: 'AttendanceIndex',
    meta: {
      title: '勤怠情報一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/attendance/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/attendance/detail'));
      });
    },
    name: 'AttendanceDetail',
    hidden: true,
    meta: {
      title: '勤怠情報詳細',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }, {
    path: '/attendance/add_attendance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/attendance/add_attendance'));
      });
    },
    name: 'AddAttendance',
    hidden: true,
    meta: {
      title: '勤怠情報の追加',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }]
}, {
  path: '/labor_notice',
  component: _layout.default,
  redirect: '/labor_notice',
  meta: {
    title: '労働通知書',
    roles: ['admin', 'editor']
  },
  children: [{
    path: '/labor_notice/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/labor_notice/index'));
      });
    },
    name: 'LaborNoticeIndex',
    meta: {
      title: '労働通知書一覧',
      roles: ['admin', 'editor']
    }
  }, {
    path: '/labor_notice/detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/labor_notice/detail'));
      });
    },
    name: 'LaborNoticeDetail',
    hidden: true,
    meta: {
      title: '労働通知書詳細',
      breadcrumb: false,
      noCache: true,
      roles: ['admin']
    }
  }]
}, {
  path: '/chat',
  component: _layout.default,
  redirect: '/chat',
  meta: {
    title: 'チャット',
    roles: ['admin', 'editor'] // you can set roles in root nav
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/chat/chat_client'));
      });
    },
    name: 'ChatClient',
    meta: {
      title: 'チャット',
      noCache: true,
      roles: ['admin', 'editor']
    }
  }]
}, {
  path: '/staff/select_detail',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/staff/select_detail'));
    });
  },
  name: 'StaffDetail',
  hidden: true,
  meta: {
    title: 'スタッフ情報',
    noCache: true,
    roles: ['admin', 'editor']
  }
}, {
  path: '/client/mypage',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/client/mypage'));
    });
  },
  name: 'ClientMypage',
  hidden: true,
  meta: {
    title: 'マイページ',
    breadcrumb: false,
    noCache: true,
    roles: ['admin']
  }
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
router.beforeEach(function (to, from, next) {
  next();
});
var _default = exports.default = router;
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_QUERY = exports.USERS_LIST_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2;
var USERS_LIST_QUERY = exports.USERS_LIST_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($searchName: String, $searchStaffType: String) {\n  user (where: {\n    _and: [ \n      {name: {_ilike: $searchName}},\n      {staff_type: {_ilike: $searchStaffType}}\n    ]\n  }) {\n    id\n    uid\n    name\n    name_furigana\n    avatar\n    birthday\n    password\n    gender\n    has_mate\n    has_child\n    age\n    blood_type\n    blood_type_rh\n    post_code\n    address\n    address_2\n    address_3\n    home_station\n    home_station_line\n    home_station_line_2\n    phone_number\n    phone_number_memo\n    mobile_phone_number\n    mobile_phone_number_memo\n    entry_date\n    is_search\n    staff_type\n    admin_name\n    email\n    site_user_id\n    created_at\n    updated_at\n    site_user_name\n  }\n}\n"])));
var USER_QUERY = exports.USER_QUERY = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: String){\n  user(where: {id: {_eq: $id}}) {\n    id\n    uid\n    name\n    name_furigana\n    avatar\n    birthday\n    password\n    gender\n    has_mate\n    has_child\n    age\n    blood_type\n    blood_type_rh\n    post_code\n    address\n    address_2\n    address_3\n    home_station\n    home_station_line\n    home_station_line_2\n    phone_number\n    phone_number_memo\n    mobile_phone_number\n    mobile_phone_number_memo\n    entry_date\n    is_search\n    staff_type\n    admin_name\n    email\n    site_user_id\n    created_at\n    updated_at\n    site_user_name\n    display_information\n  }\n}\n"])));
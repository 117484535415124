var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "h3",
          { staticClass: "header-title" },
          [
            _c(
              "el-button",
              {
                attrs: { plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.goToBack()
                  },
                },
              },
              [_vm._v("戻る")]
            ),
            _vm._v(" 略歴"),
          ],
          1
        ),
        _vm._v(" "),
        _vm.display_information.disp_name === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.user,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "氏名", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "name_furigana",
                    label: "氏名（ふりがな）",
                    align: "center",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.user,
              border: "",
              fit: "",
            },
          },
          [
            _vm.display_information.disp_birthday === true
              ? _c("el-table-column", {
                  attrs: { label: "生年月日", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.displayDateTime(
                                    scope.row.birthday,
                                    "YYYY年MM月DD日"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    134338187
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.display_information.disp_age === true
              ? _c("el-table-column", {
                  attrs: { prop: "age", label: "年齢", align: "center" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.display_information.disp_sex === true
              ? _c("el-table-column", {
                  attrs: { prop: "gender", label: "性別", align: "center" },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.display_information.disp_address === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.user,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    "min-width": "20",
                    label: "郵便番号",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.post_code) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3183780695
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "現住所", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.address) +
                                "    " +
                                _vm._s(scope.row.address_2) +
                                "    " +
                                _vm._s(scope.row.address_3) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1643202538
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.display_information.disp_final_education === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.form,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": "8", label: "年", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.final_education_year) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3571026164
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "8", label: "月", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.final_education_month) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1003210123
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "最終学歴", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.final_education_exp) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2659887446
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.display_information.disp_work_history === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.form[0].work_exp_list,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": "8", label: "年", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.year) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1747532082
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "min-width": "8", label: "月", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.month) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    624842061
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "職歴（直近のもの）", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.exp) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3311363088
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.display_information.disp_qualification === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.form,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "資格", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "left" },
                              },
                              [_vm._v(_vm._s(scope.row.requirements))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2108958123
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-style": { color: "gray", background: "#DCF2FF" },
              data: _vm.form,
              border: "",
              fit: "",
            },
          },
          [
            _vm.display_information.disp_transportation_facilities === true
              ? _c("el-table-column", {
                  attrs: {
                    "min-width": "35",
                    label: "交通機関",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "center" },
                              },
                              [_vm._v(_vm._s(scope.row.transportation))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    412603433
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.display_information.disp_desired_work_day === true
              ? _c("el-table-column", {
                  attrs: {
                    "min-width": "15",
                    label: "勤務希望日数",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "center" },
                              },
                              [_vm._v(_vm._s(scope.row.working_days))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1161683910
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.display_information.disp_overtime === true
              ? _c("el-table-column", {
                  attrs: { "min-width": "30", label: "残業", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "center" },
                              },
                              [_vm._v(_vm._s(scope.row.overtime))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4094298892
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.display_information.disp_preferred_date === true
              ? _c("el-table-column", {
                  attrs: {
                    "min-width": "20",
                    label: "希望スタート日時",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "center" },
                              },
                              [_vm._v(_vm._s(scope.row.start_dt))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1939448600
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.display_information.disp_pr_comment === true
          ? _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "header-cell-style": { color: "gray", background: "#DCF2FF" },
                  data: _vm.form,
                  border: "",
                  fit: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "PR内容", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: { "white-space": "pre" },
                                attrs: { align: "left" },
                              },
                              [_vm._v(_vm._s(scope.row.pr_comment))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    402584553
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("el-divider", { staticClass: "divider" }),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { plain: "" },
            on: {
              click: function ($event) {
                return _vm.goToBack()
              },
            },
          },
          [_vm._v("戻る")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
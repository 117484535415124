"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.auth.token;
  },
  avatar: function avatar(state) {
    return state.auth.avatar;
  },
  name: function name(state) {
    return state.auth.name;
  },
  introduction: function introduction(state) {
    return state.auth.introduction;
  },
  roles: function roles(state) {
    return state.auth.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  admin: function admin(state) {
    return state.admin.admin;
  },
  admins: function admins(state) {
    return state.admin.admins;
  },
  // currentClient: state => state.auth.currentClient,
  adminsAggregate: function adminsAggregate(state) {
    return state.admin.adminsAggregate;
  },
  adminTotalCount: function adminTotalCount(state) {
    return state.admin.totalCount;
  },
  recruitments: function recruitments(state) {
    return state.recruitment.recruitments;
  },
  recruitment: function recruitment(state) {
    return state.recruitment.recruitment;
  },
  page_top: function page_top(state) {
    return state.recruitment.page_top;
  },
  page_last: function page_last(state) {
    return state.recruitment.page_last;
  },
  page_size: function page_size(state) {
    return state.recruitment.page_size;
  },
  page_count: function page_count(state) {
    return state.recruitment.page_count;
  },
  chatRoom: function chatRoom(state) {
    return state.chat.room;
  },
  chatRooms: function chatRooms(state) {
    return state.chat.rooms;
  },
  chatRoomUsers: function chatRoomUsers(state) {
    return state.chat.roomUsers;
  },
  chatMessages: function chatMessages(state) {
    return state.chat.messages;
  },
  recruitmentMessages: function recruitmentMessages(state) {
    return state.recruitment.messages;
  },
  searchActive: function searchActive(state) {
    return state.recruitment.searchActive;
  },
  searchRecruitmentText: function searchRecruitmentText(state) {
    return state.recruitment.searchRecruitmentText;
  },
  newss: function newss(state) {
    return state.news.newss;
  },
  news: function news(state) {
    return state.news.news;
  },
  newsDistributions: function newsDistributions(state) {
    return state.news.newsDistributions;
  },
  newsSurveyAggregate: function newsSurveyAggregate(state) {
    return state.news.newsSurveyAggregate;
  },
  newsSurveyAnswers: function newsSurveyAnswers(state) {
    return state.news.newsSurveyAnswers;
  },
  searchNewsText: function searchNewsText(state) {
    return state.news.searchNewsText;
  },
  searchNewsDistribution: function searchNewsDistribution(state) {
    return state.news.searchNewsDistribution;
  },
  users: function users(state) {
    return state.user.users;
  },
  user: function user(state) {
    return state.user.user;
  },
  searchUserName: function searchUserName(state) {
    return state.user.searchUserName;
  },
  searchStaffType: function searchStaffType(state) {
    return state.user.searchStaffType;
  },
  clients_invoice: function clients_invoice(state) {
    return state.client.clients_invoice;
  },
  clients_contract: function clients_contract(state) {
    return state.client.clients_contract;
  },
  clients_labor_notice: function clients_labor_notice(state) {
    return state.client.clients_labor_notice;
  },
  searchClientInvoiceWord: function searchClientInvoiceWord(state) {
    return state.client.searchInvoiceWord;
  },
  searchClientContractWord: function searchClientContractWord(state) {
    return state.client.searchContractWord;
  },
  searchAttendancWord: function searchAttendancWord(state) {
    return state.client.searchAttendancWord;
  },
  searchClientLaborNoticeWord: function searchClientLaborNoticeWord(state) {
    return state.client.searchLaborNoticeWord;
  },
  searchClientContractAcceptance: function searchClientContractAcceptance(state) {
    return state.client.searchContractAcceptance;
  },
  searchClientInvoiceAcceptance: function searchClientInvoiceAcceptance(state) {
    return state.client.searchInvoiceAcceptance;
  },
  clients_attendance: function clients_attendance(state) {
    return state.client.clients_attendance;
  },
  page_doc_top: function page_doc_top(state) {
    return state.client.page_top;
  },
  page_doc_last: function page_doc_last(state) {
    return state.client.page_last;
  },
  page_doc_size: function page_doc_size(state) {
    return state.client.page_size;
  },
  page_doc_count: function page_doc_count(state) {
    return state.client.page_count;
  },
  fbuser: function fbuser(state) {
    return state.auth.user;
  }
};
var _default = exports.default = getters;
"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_CLIENT_DOC_RELEASE_COUNT = exports.GET_CLIENT_CONTRACT = exports.GET_CLIENT_CHAT_COUNT = exports.CLIENT_QUERY = exports.CLIENTS_CONTRACT_LIST_QUERY_EX = exports.CLIENTS_CONTRACT_LIST_QUERY = exports.CLIENTS_CONTRACT_LIST_COUNT_EX = exports.CLIENTS_CONTRACT_LIST_COUNT = exports.CLIENTS_ATTENDANCE_LIST_QUERY = exports.CHAT_MESSAGES_COUNT = exports.CHAT_CLIENT_ROOM_QUERY = exports.CHAT_CLIENT_MESSAGE_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
var CLIENT_QUERY = exports.CLIENT_QUERY = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($uid: uuid) {\n  client_user(where: {uid: {_eq: $uid}}) {\n    id\n    uid\n    company_name\n    ceo_name\n    zip_code\n    address1\n    address2\n    phone_number\n    fax_number\n    mobile_phone\n    mail_address\n    occupation\n    business_content\n    login_id\n    login_password\n    is_login\n    update_info\n    created_at\n    updated_at\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_COUNT = exports.CLIENTS_CONTRACT_LIST_COUNT = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_release: Boolean) {\n  client_file_link_aggregate(order_by: {client_reading_flag: desc, release_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, is_release: {_eq: $is_release}, _or: {title: {_ilike: $searchWord}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_COUNT_EX = exports.CLIENTS_CONTRACT_LIST_COUNT_EX = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_date_of_acceptance_null: Boolean, $is_release: Boolean) {\n  client_file_link_aggregate(order_by: {client_reading_flag: desc, release_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, is_release: {_eq: $is_release}, _or: {title: {_ilike: $searchWord}, date_of_acceptance: {_is_null: $is_date_of_acceptance_null}}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_QUERY = exports.CLIENTS_CONTRACT_LIST_QUERY = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_release: Boolean, $page_top: Int, $page_size: Int) {\n  client_file_link(order_by: {client_reading_flag: desc, release_date: desc},\n    where: {\n      file_type: {_eq: $file_type},\n      client_id: {_eq: $client_id},\n      is_acceptance: {_eq: $is_acceptance},\n      is_release: {_eq: $is_release},\n      _or: { title: {_ilike: $searchWord}\n      }}, offset: $page_top, limit: $page_size) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    release_date\n    client_reading_flag\n  }\n}\n"])));
var CLIENTS_CONTRACT_LIST_QUERY_EX = exports.CLIENTS_CONTRACT_LIST_QUERY_EX = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_date_of_acceptance_null: Boolean, $is_release: Boolean, $page_top: Int, $page_size: Int) {\n  client_file_link(order_by: {client_reading_flag: desc, release_date: desc}, where: {file_type: {_eq: $file_type}, client_id: {_eq: $client_id}, is_acceptance: {_eq: $is_acceptance}, is_release: {_eq: $is_release}, _or: {title: {_ilike: $searchWord}}, date_of_acceptance: {_is_null: $is_date_of_acceptance_null}}, offset: $page_top, limit: $page_size) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    release_date\n    client_reading_flag\n  }\n}\n"])));
var CLIENTS_ATTENDANCE_LIST_QUERY = exports.CLIENTS_ATTENDANCE_LIST_QUERY = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery($client_id: Int, $file_type: Int, $searchWord: String, $is_acceptance: Boolean, $is_release: Boolean, $page_top: Int, $page_size: Int) {\n  client_file_link(order_by: {client_reading_flag: desc, create_date: desc},\n    where: {\n      file_type: {_eq: $file_type},\n      client_id: {_eq: $client_id},\n      is_acceptance: {_eq: $is_acceptance},\n      is_release: {_eq: $is_release},\n      _or: { title: {_ilike: $searchWord}\n      }}, offset: $page_top, limit: $page_size) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    release_date\n    client_reading_flag\n  }\n}\n"])));
var GET_CLIENT_CONTRACT = exports.GET_CLIENT_CONTRACT = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: Int) {\n  client_file_link(\n    where: { id: {_eq: $id }}) {\n    id\n    client_id\n    title\n    file_type\n    file_url\n    file_name\n    attach_file_type\n    is_acceptance\n    date_of_acceptance\n    create_date\n    release_date\n    client_reading_flag\n  }\n}\n"])));
var GET_CLIENT_DOC_RELEASE_COUNT = exports.GET_CLIENT_DOC_RELEASE_COUNT = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_id: Int, $file_type: Int) {\n  client_file_link_aggregate(where: {file_type: {_eq: $file_type}, is_release: {_eq: true}, client_reading_flag: {_eq: true}, client_id: {_eq: $client_id}}) {\n    aggregate {\n      count(columns: is_release)\n    }\n  }\n}\n"])));
var CHAT_CLIENT_ROOM_QUERY = exports.CHAT_CLIENT_ROOM_QUERY = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_uid: uuid) {\n  client_message(where: {client_uid: {_eq: $client_uid}}, order_by: {create_date: desc}) {\n    id\n    admin_uid\n    client_uid\n    message\n    reading_flag\n    create_date\n    client_user {\n      company_name\n    }\n    admin_user {\n      name\n    }\n  }\n}\n"])));
var CHAT_CLIENT_MESSAGE_QUERY = exports.CHAT_CLIENT_MESSAGE_QUERY = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nquery ($client_uid: uuid) {\n  client_message(where: {client_uid: {_eq: $client_uid}}, order_by: {create_date: asc}) {\n    id\n    admin_uid\n    client_uid\n    message\n    reading_flag\n    from_user_type\n    create_date\n    client_user {\n      company_name\n    }\n    admin_user {\n      name\n    }\n  }\n}\n"])));
var CHAT_MESSAGES_COUNT = exports.CHAT_MESSAGES_COUNT = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nquery ChatMessage($client_uid: uuid) {\n  client_message_aggregate(where: {client_uid: {_eq: $client_uid}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var GET_CLIENT_CHAT_COUNT = exports.GET_CLIENT_CHAT_COUNT = (0, _graphqlTag.default)(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["\nquery ClientChatCount($client_uid: uuid) {\n  client_message_aggregate(where: {from_user_type: {_eq: 1}, client_uid: {_eq: $client_uid}}) {\n    aggregate {\n      sum {\n        unread_count\n      }\n    }\n  }\n}\n\n"])));